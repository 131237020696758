<template>
  <div class="modal modal-kassa row" tabindex="-1">
    <div class="col s12 m6">
      <div class="card blue-grey darken-1">
        <div class="card-content white-text">
          <span class="card-title">Card Title</span>
          <p>
            I am a very simple card. I am good at containing small bits of
            information. I am convenient because I require little markup to use
            effectively.
          </p>
        </div>
        <div class="card-action">
          <a href="#">This is a link</a>
          <a href="#">This is a link</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          header: "Hello",
          text: "Text",
          ok: "Ok"
        };
      }
    }
  },
  data: () => ({}),
  methods: {
    close() {
      this.$emit("close");
    },
    async ok() {}
  },
  async mounted() {}
};
</script>

<style scoped></style>
